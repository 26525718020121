import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils';
import { NbAuthJWTToken, NbAuthService, NbAuthResult } from '@nebular/auth';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeOrganisationModalComponent } from '../../../main/shared-components/change-organisation-modal/change-organisation-modal.component';
import { OrganisationService } from '../../../services/organisation-service';
import { Organisation } from '../../../models/organisation.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() position = 'normal';

  user: any;
  organisation: Organisation;
  userMenu = [{ title: 'Change Organisation' }, { title: 'Log out' }];
  menuSubscription$: Subscription;
  authSubscription$: Subscription;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private authService: NbAuthService,
    private router: Router,
    private modalService: NgbModal,
    private organisationService: OrganisationService
  ) {
    this.authSubscription$ = this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        // console.log('token is valid', token.getPayload());
        this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
      }
    });
  }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.nick);
    this.menuSubscription$ = this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => {
          return tag === 'userMenu';
        }),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        console.log('Clicked ' + title);
        if (title === 'Log out') {
          console.log('Logout');

          this.authService.logout('email').subscribe((result: NbAuthResult) => {
            // push to back of queue to avoid race conditions with other subscribers
            setTimeout(() => {
              this.router.navigateByUrl('/auth/login');
            }, 500);
          });
        }

        if (title === 'Change Password') {
          // const activeModal = this.modalService.open(ChangePasswordModalComponent, { size: 'lg', container: 'nb-layout'});
          // const user = this.user;
          // activeModal.componentInstance.user = {
          //   id : user.id,
          //   firstName : user.firstName,
          //   lastName : user.lastName,
          //   email : user.email
          // };
          // activeModal.componentInstance.onSave = (user) => {
          //   // show success message;
          // }
        }

        if (title === 'Change Organisation') {
          this.modalService.open(ChangeOrganisationModalComponent, { size: 'lg', container: 'nb-layout' });
        }
      });

    this.organisationService.getCurrentOrganisation().then((org) => {
      this.organisation = org;
    });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  ngOnDestroy() {
    if (this.authSubscription$) this.authSubscription$.unsubscribe();
    if (this.menuSubscription$) this.menuSubscription$.unsubscribe();
  }
}
