import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NbAuthService, NbAuthToken } from '@nebular/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: NbAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // console.log('intercepting...');
    // this.authenticationService.getToken();
    // if (currentUser && currentUser.token) {
    //     request = request.clone({
    //         setHeaders: {
    //             Authorization: `Bearer ${currentUser.token}`
    //         }
    //     });
    // }
    return this.authService.isAuthenticatedOrRefresh().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          // console.log('authenticated');
          return this.authService.getToken().pipe(
            switchMap((token: NbAuthToken) => {
              const JWT = `Bearer ${token.getValue()}`;
              request = request.clone({
                setHeaders: {
                  Authorization: JWT,
                },
              });

              return next.handle(request);
            })
          );
        } else {
          console.log('not authenticated');
          // Request is sent to server without authentication so that the client code
          // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
          return next.handle(request);
        }
      })
    );
  }
}
