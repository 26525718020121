import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserMutationPayload, IUserCreationPayload, UserService } from '../../../services/user-service';
import { PermissionService } from '../../../services/permission-service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganisationService } from '../../../services/organisation-service';
import { Organisation } from '../../../models/organisation.model';
import { UserOrganisationService } from '../../../services/user-organisation.service';


@Component({
    selector: 'ngx-change-organisation-modal',
    templateUrl: './change-organisation-modal.component.html'
})
export class ChangeOrganisationModalComponent {

    errorMessage: string = '';
    orgs: Organisation[] = [];
    filteredOrgs: Organisation[] = [];
    currentOrgId: string;
    isLoading: boolean = true;
    isChangingSession: boolean = false;

    constructor(private activeModal: NgbActiveModal, private organisationService: OrganisationService, private userService: UserService, private userOrganisationService: UserOrganisationService) {
        this.isLoading = true;
        const user = this.userService.currentUser();
        this.currentOrgId = user.currentOrganisationId;
        this.organisationService.getOrganisations().then(orgs => {
            this.orgs = this.filteredOrgs = orgs;
            this.isLoading = false;

        }).catch(error => {
            this.errorMessage = error.message;
            this.isLoading = false;
        });
    }

    doneClicked() {

        this.errorMessage = '';
        this.activeModal.close();
    }

    switchOrganisation(organisationId) {
        console.log('switchOrganisation', organisationId);
        this.userOrganisationService.switchOrganisation(organisationId).then(token => {
            this.isChangingSession = true;

            setTimeout(() => {
                window.location.reload();
            }, 500);

        }).catch(error => {
            this.errorMessage = error.message;
        });
    }

    onSearch($event) {
        if (!$event.target.value.length) {
            this.filteredOrgs = this.orgs;
            return;
        }

        this.filteredOrgs = this.orgs.filter(org => {
            const matchesName = org.name.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1;

            return matchesName;
        });
    }


    closeModal() {
        this.activeModal.close();
    }
}
